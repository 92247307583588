<template>
  <NuxtLayout>
    <div class="py-20">
      <SectionTextIntro
        v-bind="errorMessage"
        :title-enabled="true"
      >
        <p>{{ errorMessage.content }}</p>

        <UiButton
          class="mt-10"
          type="button"
          @click="handleError"
        >
          Ga terug
        </UiButton>
      </SectionTextIntro>
    </div>
  </NuxtLayout>
</template>

<script setup lang="ts">
const currentError = useError();
const error = ref(currentError.value);

watchEffect(() => {
  error.value = currentError.value?.statusCode ? currentError.value : error.value;
});

definePageMeta({
  layout: 'default',
});

const errorMessage = computed(() => ({
  '404': {
    title: '404',
    content: 'De pagina die je probeerde te bezoeken bestaat niet (meer).',
  },
})['' + error.value?.statusCode] ?? {
  title: '500',
  content: 'Er ging iets mis op de pagina die je probeerde te bezoeken, ververs de pagina of probeer het op een later moment nog een keer.',
});

function handleError() {
  clearError({
    redirect: '/',
  });
}
</script>
