<template>
  <Icon
    v-if="name.includes(':')"
    :class="classes"
    :name="name"
  />
  <component
    :is="component"
    v-else
    :class="classes"
  />
</template>

<script lang="ts">
const iconsRaw = import.meta.glob('../icons/*.vue');

const icons = Object.fromEntries(
  Object.entries(iconsRaw).map(([key, component]) => {
    // Convert ComponentName.vue to component-name
    key = key
      .replace(/.*?\/([a-z0-9]+)\.vue/i, '$1')
      .replace(/([a-z])([A-Z0-9])/g, '$1-$2')
      .toLowerCase()
    ;

    return [key, defineAsyncComponent(component)];
  }),
);

export const iconNames = Object.keys(icons);
</script>

<script setup lang="ts">
const props = withDefaults(defineProps<{
  name: string
  size?: 'large' | 'medium' | 'small' | 'tiny'
}>(), {
  size: undefined,
});

const classes = computed(() => ({
  'h-2 w-2': props.size === 'tiny',
  'h-4 w-4': props.size === 'small',
  'h-6 w-6': props.size === 'medium',
  'h-8 w-8': props.size === 'large',
}));

const component = computed(() => icons[props.name]);
</script>
