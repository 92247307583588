<template>
  <component
    :is="to ? NuxtLink : 'button'"
    v-tooltip="tooltip ? label : undefined"
    :aria-label="tooltip || labelResponsive === false ? label : undefined"
    class="button"
    :class="{
      'button--is-active': active,
      'button--size-small': size === 'small',
      'button--size-tiny': size === 'tiny',
      'button--type-primary': ['primary', 'submit'].includes(type),
      'button--type-secondary': ['destructive', 'secondary'].includes(type),
      'button--is-rounded': shape === 'round' || (icon && !label && !$slots.default) || tooltip,
      'button--is-rounded-responsive': labelResponsive === false,
    }"
    :to="to"
    :target="openInNewTab ? '_blank' : undefined"
    :type="type === 'submit' ? 'submit' : 'button'"
    :disabled="to ? undefined : isDisabled"
    @click="$emit('click')"
  >
    <ui-icon
      v-if="icon"
      size="small"
      :name="icon"
    />

    <slot
      v-if="!tooltip && ($slots.default || label || labelResponsive)"
    >
      <template v-if="labelResponsive !== undefined">
        <span
          v-if="labelResponsive"
          class="md:hidden"
        >
          {{ labelResponsive }}
        </span>

        <span class="max-md:hidden">
          {{ label }}
        </span>
      </template>
      <template v-else>
        {{ label }}
      </template>
    </slot>
  </component>
</template>

<script setup lang="ts">
import type {RouteLocationRaw} from 'vue-router';
import {NuxtLink} from '#components';
import {ButtonGroupSymbol} from './ButtonGroup.interface';
import {parentSymbol as FormKitParentSymbol} from '@formkit/vue';

export type Size = 'default' | 'small' | 'tiny';
export type Shape = 'round';

const props = withDefaults(defineProps<{
  active?: boolean
  disabled?: boolean
  icon?: string
  label?: string
  labelResponsive?: string | false
  loading?: boolean
  shape?: Shape
  size?: Size
  to?: RouteLocationRaw
  openInNewTab?: boolean
  tooltip?: boolean
  type?: 'destructive' | 'primary' | 'secondary' | 'submit'
}>(), {
  icon: undefined,
  label: undefined,
  labelResponsive: undefined,
  shape: undefined,
  size: undefined,
  to: undefined,
  type: undefined,
});

defineEmits<{
  (e: 'click'): void
}>();

const formKitParent = inject(FormKitParentSymbol, null);
const isFormKitDisabled = ref(false);

watchEffect(() => {
  if (props.type !== 'submit' || !formKitParent) {
    return;
  }

  formKitParent.hook.prop((payload, next) => {
    if (payload.prop === 'disabled') {
      isFormKitDisabled.value = !!payload.value;
    }

    return next(payload);
  });
});

const isDisabled = computed(() => props.disabled || props.loading || isFormKitDisabled.value);

const context = inject<{
  shape?: Shape
  size?: Size
}>(ButtonGroupSymbol, {});

const shape = computed(() => props.shape ?? context?.shape ?? 'default');
const size = computed(() => props.size ?? context?.size ?? 'default');
</script>
