import {HTMLAttributes} from 'vue';
import type {SectionBackground} from '~/types/section';

export function sectionBackgroundClass(background: SectionBackground | undefined, withNeighborAwareness = true): HTMLAttributes['class'] {
  background = background ?? 'default';

  return [
    {
      [`section--background-${background}`]: withNeighborAwareness,
    },
    {
      default: '',
      dark: 'dark',
      light: 'bg-white',
      secondary: 'dark bg-secondary',
    }[background] ?? '',
  ];
}
