import {createGtm, useGtm} from '@gtm-support/vue-gtm';

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();

  if (!config.public.googleTagManager) {
    return;
  }

  nuxtApp.vueApp.use(createGtm({
    id: config.public.googleTagManager,
    vueRouter: useRouter(),
    enabled: true,
  }));

  const user = useSofieUser();

  watch(user, user => {
    if (user) {
      useGtm()?.push({
        'user_id': user.uuid,
      });
    }
  }, {immediate: true});
});
