import {nl} from '@formkit/i18n';
import {createConfig} from '@formkit/core';
import {createInput} from '@formkit/vue';
import {generateClasses} from '@formkit/themes';
import * as iban from '../core/formkit-rules/iban';
import {LazyFormkitModelSelect, LazyFormkitSlideSelect, LazyFormkitTags} from '#components';
import {addAsteriskPlugin} from '~/utils/formkit/asterisk-plugin';

export default createConfig({
  plugins: [addAsteriskPlugin],
  locales: {nl},
  locale: 'nl',
  inputs: {
    slideSelect: createInput(LazyFormkitSlideSelect, {
      props: ['options'],
    }),
    tags: createInput(LazyFormkitTags, {
      props: ['mode'],
    }),
    modelSelect: createInput(LazyFormkitModelSelect, {
      props: ['options', 'labelField', 'keyField'],
    }),
  },
  rules: {
    iban: iban.rule,
  },
  icons: {
    euro: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 13"><path fill="currentColor" d="M9.3 11.6a4 4 0 0 1-1.2.5 6.3 6.3 0 0 1-3.5-.1 4.6 4.6 0 0 1-2.4-2c-.3-.5-.5-1-.6-1.6H.2l.5-1.3h.7V5.6H.3l.5-1.4h.8a5.5 5.5 0 0 1 1.9-2.8 5.7 5.7 0 0 1 3.3-1 6.2 6.2 0 0 1 2.5.5l-.6 1.6-1-.3-1-.1A3.3 3.3 0 0 0 4 3.3a3 3 0 0 0-.4 1h4.6l-.5 1.3H3.3v1.5h4L7 8.4H3.5a2.6 2.6 0 0 0 1 1.6l1 .4 1 .2a4 4 0 0 0 2.2-.5l.6 1.5Z"/></svg>',
    euroSmall: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 8 13"><path fill="currentColor" d="M7.04 1.62a2.26 2.26 0 0 0-.56-.2 3 3 0 0 0-.81-.1 2.4 2.4 0 0 0-1.86.84 4.78 4.78 0 0 0-1 2.46h3.56l-.2.77h-3.5a5.22 5.22 0 0 0-.06 1.01v.43l.03.42h3.2l-.17.77H2.74c.13 1.03.44 1.84.94 2.43.5.57 1.12.86 1.86.86.34 0 .63-.04.88-.13.25-.08.48-.18.69-.3l.19.91a3.68 3.68 0 0 1-1.87.47 3.4 3.4 0 0 1-1.46-.3c-.43-.22-.8-.51-1.12-.89-.3-.38-.57-.83-.77-1.34a7.6 7.6 0 0 1-.43-1.71h-1l.18-.77h.77a3.75 3.75 0 0 1-.05-.42V6.4a8.5 8.5 0 0 1 .07-1H.67l.18-.78h.83a5.92 5.92 0 0 1 1.4-3.16A3.45 3.45 0 0 1 5.62.42 4.35 4.35 0 0 1 7.3.74l-.26.88Z"/></svg>',
    folder: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32"><path fill="currentColor" stroke="#191617" d="M4 25.8h22.2v1.7H4a2 2 0 0 1-1.5-.6 2 2 0 0 1-.7-1.6V8.5h1.7v17.3H4ZM18.3 5.7l.2.1H28a2 2 0 0 1 1.5.7c.5.4.7.9.7 1.5v12a2 2 0 0 1-.7 1.5 2 2 0 0 1-1.5.7H9.3a2 2 0 0 1-1.5-.7 2 2 0 0 1-.6-1.5V5.3c0-.6.2-1 .6-1.5a2 2 0 0 1 1.5-.6h6.5l2.5 2.5ZM8.8 20v.5h19.7v-13H17.8L15.3 5l-.2-.2H8.8V20Z"/></svg>',
    search: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" viewBox="0 0 21 21"><path stroke-linecap="round" d="m20 20-4-4m2-6a8 8 0 1 1-17 0 8 8 0 0 1 17 0v0Z"/></svg>',
    select: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 11"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 8 8 8-8"/></svg>',
  },
  messages: {
    nl: {
      validation: {
        iban: iban.message,
      },
    },
  },
  config: {
    classes: generateClasses({
      global: {
        help: 'text-xxs opacity-80 mt-2',
        input: `
          appearance-none
          block
          bg-transparent
          p-3
          outline-none focus:ring-0
          w-full
        `,
        inner: `
          bg-white text-black
          border border-black-400
          formkit-invalid:border-red
          formkit-invalid:focus-within:border-secondary-900
          focus-within:border-secondary-900
          formkit-disabled:opacity-60
          formkit-disabled:bg-black-300
        `,
        label: 'text-xs font-bold',
        legend: 'text-xs font-bold',
        messages: 'list-none p-0 mt-2 mb-0 text-xxs text-red',
        message: '',
        outer: 'mb-6 last:mb-0',
        wrapper: 'flex flex-col gap-1',
        prefixIcon: 'w-7 flex items-center justify-end self-stretch grow-0 shrink-0 [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em]',
        suffixIcon: 'w-7 pr-3 flex self-stretch grow-0 shrink-0 [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
      },
      'family:box': {
        input: '$reset accent-primary',
        label: '$reset text-sm',
        inner: '$reset',
        wrapper: '$reset flex gap-2 items-center',
      },
      'family:button': {
        input: '$reset btn btn-primary',
        wrapper: '$reset',
      },
      'family:text': {
        inner: 'flex items-center',
        input: 'w-full px-4 py-3 border-none placeholder-gray-400',
      },
      name: {
        label: 'hidden',
        inner: 'border-none',
      },
      address: {
        label: 'hidden',
        inner: '$reset',
      },
      checkbox: {
        inner: '$reset',
        input: 'mt-2',
      },
      date: {
        input: 'leading-relaxed !py-2.5',
      },
      select: {
        inner: 'flex relative items-center',
        input: 'w-full pl-3 pr-8 py-3 border-none text-gray-700 placeholder-gray-400 formkit-multiple:p-0 data-[placeholder="true"]:text-gray-400 formkit-multiple:data-[placeholder="true"]:text-inherit',
        selectIcon: 'flex shrink-0 w-4 mr-3 -ml-[1.5em] pointer-events-none [&>svg]:h-full [&>svg]:w-full',
      },
      textarea: {
        input: 'w-full min-h-[120px]',
      },
      color: {
        label: 'block mb-1 font-bold',
        input: 'w-16 h-8 bg-white appearance-none cursor-pointer border border-black-400 mb-2 p-1',
      },
      editor: {
        inner: 'grid',
        input: '$reset outline-none p-5',
        label: 'block mb-1 font-bold',
        toolbar: 'flex gap-x-1 items-center p-2 border-b border-black-400',
        toolbarButtonActive: 'text-primary',
      },
      file: {
        label: 'block mb-1 font-bold',
        inner: 'relative flex items-center cursor-pointer formkit-multiple:[&>button]:absolute border border-black-400 w-full min-h-[50px] p-3 outline-none focus:ring-0 formkit-disabled:bg-black-300',
        fileItem: 'flex items-center text-gray-800 mb-1 last:mb-0',
        fileItemIcon: 'w-4 mr-2 shrink-0',
        fileList: 'shrink grow peer formkit-multiple:data-[has-multiple="true"]:mb-6',
        fileName: 'break-all grow text-ellipsis',
        fileRemove: 'relative z-[2] ml-auto text-[0px] hover:text-red-500 pl-2 peer-data-[has-multiple=true]:text-sm peer-data-[has-multiple=true]:text-blue-500 peer-data-[has-multiple=true]:ml-3 peer-data-[has-multiple=true]:mb-2 formkit-multiple:bottom-[0.15em] formkit-multiple:pl-0 formkit-multiple:ml-0 formkit-multiple:left-[1em] formkit-multiple:formkit-prefix-icon:left-[3.75em]',
        fileRemoveIcon: 'block text-base w-3 relative z-[2]',
        input: 'cursor-pointer text-transparent absolute top-0 right-0 left-0 bottom-0 opacity-0 z-[2]',
        noFiles: 'flex w-full h-full items-center formkit-disabled:text-black-400 text-black cursor-pointer',
        noFilesIcon: 'w-4 mr-2',
      },
      range: {
        label: 'block mb-1 font-bold',
        input: 'form-range slider-drag appearance-none w-full h-2 p-0 bg-black-300 focus:outline-none focus:ring-0 focus:shadow-none',
      },
      'slide-select': {
        inner: '$reset',
      },
      tags: {
        label: 'block mb-1 font-bold',
        inner: '$reset',
      },
    }),
  },
});
