import { default as _iconsxVpcQr7BwrMeta } from "/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/core/pages/_icons.vue?macro=true";
import { default as _91_46_46_46page_938y5d4iWM5wMeta } from "/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/website/pages/[...page].vue?macro=true";
import { default as _91child_93YHzvzfd6Z7Meta } from "/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/website/pages/communities/[slug]/[child].vue?macro=true";
import { default as alleKtb2inikTSMeta } from "/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/website/pages/communities/[slug]/index/alle.vue?macro=true";
import { default as indexRHWBYIE2fKMeta } from "/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/website/pages/communities/[slug]/index/index.vue?macro=true";
import { default as kaart6bD4cjjIQxMeta } from "/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/website/pages/communities/[slug]/index/kaart.vue?macro=true";
import { default as indexwOCzQyX3r5Meta } from "/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/website/pages/communities/[slug]/index.vue?macro=true";
import { default as _91slug_93c2qj9oxIU1Meta } from "/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/website/pages/communities/[slug].vue?macro=true";
import { default as _91id_93aCJoWd7CC8Meta } from "/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/website/pages/doopgetuigenissen/[date]/[id].vue?macro=true";
import { default as indexjdJG5fy6iMMeta } from "/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/website/pages/doopgetuigenissen/[date]/index.vue?macro=true";
import { default as _91date_93lj0Mw8TGBvMeta } from "/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/website/pages/doopgetuigenissen/[date].vue?macro=true";
import { default as indexOnYz184bjPMeta } from "/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/website/pages/doopgetuigenissen/index.vue?macro=true";
import { default as _91date_931REEuvGLXbMeta } from "/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/website/pages/evenementen/[uuid]/[date].vue?macro=true";
import { default as indexdiuQZVe6imMeta } from "/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/website/pages/evenementen/index.vue?macro=true";
import { default as giftMQVsrrgGHbMeta } from "/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/website/pages/geven/gift.vue?macro=true";
import { default as machtigingc6z3f4JpPNMeta } from "/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/website/pages/geven/machtiging.vue?macro=true";
import { default as _91slug_93ipEin0VURGMeta } from "/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/website/pages/media/[slug].vue?macro=true";
import { default as archief7Th0Ut0t6ZMeta } from "/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/website/pages/media/archief.vue?macro=true";
import { default as indexvAVCs3hJ2BMeta } from "/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/website/pages/media/index.vue?macro=true";
import { default as _91slug_937bRmpDTA3JMeta } from "/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/website/pages/nieuws/[slug].vue?macro=true";
import { default as indexEC11ZlRFdNMeta } from "/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/website/pages/nieuws/index.vue?macro=true";
import { default as zoekenSxT3tmv9xmMeta } from "/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/website/pages/zoeken.vue?macro=true";
export default [
  {
    name: _iconsxVpcQr7BwrMeta?.name ?? "_icons",
    path: _iconsxVpcQr7BwrMeta?.path ?? "/_icons",
    meta: _iconsxVpcQr7BwrMeta || {},
    alias: _iconsxVpcQr7BwrMeta?.alias || [],
    redirect: _iconsxVpcQr7BwrMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/core/pages/_icons.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46page_938y5d4iWM5wMeta?.name ?? "page",
    path: _91_46_46_46page_938y5d4iWM5wMeta?.path ?? "/:page(.*)*",
    meta: _91_46_46_46page_938y5d4iWM5wMeta || {},
    alias: _91_46_46_46page_938y5d4iWM5wMeta?.alias || [],
    redirect: _91_46_46_46page_938y5d4iWM5wMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/website/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93c2qj9oxIU1Meta?.name ?? undefined,
    path: _91slug_93c2qj9oxIU1Meta?.path ?? "/communities/:slug()",
    meta: _91slug_93c2qj9oxIU1Meta || {},
    alias: _91slug_93c2qj9oxIU1Meta?.alias || [],
    redirect: _91slug_93c2qj9oxIU1Meta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/website/pages/communities/[slug].vue").then(m => m.default || m),
    children: [
  {
    name: _91child_93YHzvzfd6Z7Meta?.name ?? "communities-slug-child",
    path: _91child_93YHzvzfd6Z7Meta?.path ?? ":child()",
    meta: _91child_93YHzvzfd6Z7Meta || {},
    alias: _91child_93YHzvzfd6Z7Meta?.alias || [],
    redirect: _91child_93YHzvzfd6Z7Meta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/website/pages/communities/[slug]/[child].vue").then(m => m.default || m)
  },
  {
    name: indexwOCzQyX3r5Meta?.name ?? undefined,
    path: indexwOCzQyX3r5Meta?.path ?? "",
    meta: indexwOCzQyX3r5Meta || {},
    alias: indexwOCzQyX3r5Meta?.alias || [],
    redirect: indexwOCzQyX3r5Meta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/website/pages/communities/[slug]/index.vue").then(m => m.default || m),
    children: [
  {
    name: alleKtb2inikTSMeta?.name ?? "communities-slug-index-alle",
    path: alleKtb2inikTSMeta?.path ?? "alle",
    meta: alleKtb2inikTSMeta || {},
    alias: alleKtb2inikTSMeta?.alias || [],
    redirect: alleKtb2inikTSMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/website/pages/communities/[slug]/index/alle.vue").then(m => m.default || m)
  },
  {
    name: indexRHWBYIE2fKMeta?.name ?? "communities-slug-index",
    path: indexRHWBYIE2fKMeta?.path ?? "",
    meta: indexRHWBYIE2fKMeta || {},
    alias: indexRHWBYIE2fKMeta?.alias || [],
    redirect: indexRHWBYIE2fKMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/website/pages/communities/[slug]/index/index.vue").then(m => m.default || m)
  },
  {
    name: kaart6bD4cjjIQxMeta?.name ?? "communities-slug-index-kaart",
    path: kaart6bD4cjjIQxMeta?.path ?? "kaart",
    meta: kaart6bD4cjjIQxMeta || {},
    alias: kaart6bD4cjjIQxMeta?.alias || [],
    redirect: kaart6bD4cjjIQxMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/website/pages/communities/[slug]/index/kaart.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: _91date_93lj0Mw8TGBvMeta?.name ?? undefined,
    path: _91date_93lj0Mw8TGBvMeta?.path ?? "/doopgetuigenissen/:date()",
    meta: _91date_93lj0Mw8TGBvMeta || {},
    alias: _91date_93lj0Mw8TGBvMeta?.alias || [],
    redirect: _91date_93lj0Mw8TGBvMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/website/pages/doopgetuigenissen/[date].vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93aCJoWd7CC8Meta?.name ?? "doopgetuigenissen-date-id",
    path: _91id_93aCJoWd7CC8Meta?.path ?? ":id()",
    meta: _91id_93aCJoWd7CC8Meta || {},
    alias: _91id_93aCJoWd7CC8Meta?.alias || [],
    redirect: _91id_93aCJoWd7CC8Meta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/website/pages/doopgetuigenissen/[date]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexjdJG5fy6iMMeta?.name ?? "doopgetuigenissen-date",
    path: indexjdJG5fy6iMMeta?.path ?? "",
    meta: indexjdJG5fy6iMMeta || {},
    alias: indexjdJG5fy6iMMeta?.alias || [],
    redirect: indexjdJG5fy6iMMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/website/pages/doopgetuigenissen/[date]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexOnYz184bjPMeta?.name ?? "doopgetuigenissen",
    path: indexOnYz184bjPMeta?.path ?? "/doopgetuigenissen",
    meta: indexOnYz184bjPMeta || {},
    alias: indexOnYz184bjPMeta?.alias || [],
    redirect: indexOnYz184bjPMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/website/pages/doopgetuigenissen/index.vue").then(m => m.default || m)
  },
  {
    name: _91date_931REEuvGLXbMeta?.name ?? "evenementen-uuid-date",
    path: _91date_931REEuvGLXbMeta?.path ?? "/evenementen/:uuid()/:date()",
    meta: _91date_931REEuvGLXbMeta || {},
    alias: _91date_931REEuvGLXbMeta?.alias || [],
    redirect: _91date_931REEuvGLXbMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/website/pages/evenementen/[uuid]/[date].vue").then(m => m.default || m)
  },
  {
    name: indexdiuQZVe6imMeta?.name ?? "evenementen",
    path: indexdiuQZVe6imMeta?.path ?? "/evenementen",
    meta: indexdiuQZVe6imMeta || {},
    alias: indexdiuQZVe6imMeta?.alias || [],
    redirect: indexdiuQZVe6imMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/website/pages/evenementen/index.vue").then(m => m.default || m)
  },
  {
    name: giftMQVsrrgGHbMeta?.name ?? "geven-gift",
    path: giftMQVsrrgGHbMeta?.path ?? "/geven/gift",
    meta: giftMQVsrrgGHbMeta || {},
    alias: giftMQVsrrgGHbMeta?.alias || [],
    redirect: giftMQVsrrgGHbMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/website/pages/geven/gift.vue").then(m => m.default || m)
  },
  {
    name: machtigingc6z3f4JpPNMeta?.name ?? "geven-machtiging",
    path: machtigingc6z3f4JpPNMeta?.path ?? "/geven/machtiging",
    meta: machtigingc6z3f4JpPNMeta || {},
    alias: machtigingc6z3f4JpPNMeta?.alias || [],
    redirect: machtigingc6z3f4JpPNMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/website/pages/geven/machtiging.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ipEin0VURGMeta?.name ?? "media-slug",
    path: _91slug_93ipEin0VURGMeta?.path ?? "/media/:slug()",
    meta: _91slug_93ipEin0VURGMeta || {},
    alias: _91slug_93ipEin0VURGMeta?.alias || [],
    redirect: _91slug_93ipEin0VURGMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/website/pages/media/[slug].vue").then(m => m.default || m)
  },
  {
    name: archief7Th0Ut0t6ZMeta?.name ?? "media-archief",
    path: archief7Th0Ut0t6ZMeta?.path ?? "/media/archief",
    meta: archief7Th0Ut0t6ZMeta || {},
    alias: archief7Th0Ut0t6ZMeta?.alias || [],
    redirect: archief7Th0Ut0t6ZMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/website/pages/media/archief.vue").then(m => m.default || m)
  },
  {
    name: indexvAVCs3hJ2BMeta?.name ?? "media",
    path: indexvAVCs3hJ2BMeta?.path ?? "/media",
    meta: indexvAVCs3hJ2BMeta || {},
    alias: indexvAVCs3hJ2BMeta?.alias || [],
    redirect: indexvAVCs3hJ2BMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/website/pages/media/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_937bRmpDTA3JMeta?.name ?? "nieuws-slug",
    path: _91slug_937bRmpDTA3JMeta?.path ?? "/nieuws/:slug()",
    meta: _91slug_937bRmpDTA3JMeta || {},
    alias: _91slug_937bRmpDTA3JMeta?.alias || [],
    redirect: _91slug_937bRmpDTA3JMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/website/pages/nieuws/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexEC11ZlRFdNMeta?.name ?? "nieuws",
    path: indexEC11ZlRFdNMeta?.path ?? "/nieuws",
    meta: indexEC11ZlRFdNMeta || {},
    alias: indexEC11ZlRFdNMeta?.alias || [],
    redirect: indexEC11ZlRFdNMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/website/pages/nieuws/index.vue").then(m => m.default || m)
  },
  {
    name: zoekenSxT3tmv9xmMeta?.name ?? "zoeken",
    path: zoekenSxT3tmv9xmMeta?.path ?? "/zoeken",
    meta: zoekenSxT3tmv9xmMeta || {},
    alias: zoekenSxT3tmv9xmMeta?.alias || [],
    redirect: zoekenSxT3tmv9xmMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/347/nuxt/website/pages/zoeken.vue").then(m => m.default || m)
  }
]