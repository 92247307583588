import {useTenantName} from '~/server/utils/tenant';

export default defineNuxtPlugin((nuxtApp) => {
  const event = useRequestEvent();
  const tenantName = useTenantName(event);

  nuxtApp.hook('apollo:headers', (headers) => {
    const req = event?.node?.req;
    const url = useRequestURL();

    headers.value['x-referer'] = url.href;
    headers.value['x-tenant'] = tenantName;

    if (req) {
      const headerKey = req.headers['cf-ray'] ? 'cf-connecting-ip' : 'x-forwarded-for';

      headers.value = {
        ...headers.value,
        'x-forwarded-for': req.headers[headerKey] || req.connection.remoteAddress || req.socket.remoteAddress,
      };
    }
  });

  nuxtApp.hook('apollo:error', (error) => {
    if (error.networkError) {
      console.error(error.networkError);
    }
  });
});
