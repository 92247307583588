import {isValidIBAN} from 'ibantools';
import type {FormKitNode} from '@formkit/core';

export function rule(node: FormKitNode): boolean {
  if (typeof node.value !== 'string' || !node.value?.length) {
    return true;
  }

  return isValidIBAN(node.value);
}

export function message({name}): string {
  return `${name} heeft een ongeldig formaat.`;
}
